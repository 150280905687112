<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="480px">
      <template slot="title">
        <div class="check-in-records-title">{{ title }}</div>
      </template>
      <div>
        <el-form label-position="left" label-width="60px">
          <el-form-item :label="label">
            <el-select style="width: 100%;"
                       v-model="dispatchOrderUser"
                       clearable
                       :placeholder="'请选择'">
              <el-option v-for="iter in workingSalesman"
                         :key="iter.id"
                         :label="iter.name"
                         :value="iter.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmDispatchOrder">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/styles/LPopup.scss'

export default {
  name: "DispatchOrder",
  props: {
    title: {
      type: String,
      default() {
        return '派单'
      }
    },
    label: {
      type: String,
      default() {
        return '派单人'
      }
    },
    workingSalesman: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data(){
    return {
      dialogVisible: false,
      dispatchOrderUser: '',
    }
  },
  methods: {
    open(){
      this.dialogVisible = true
    },
    confirmDispatchOrder(){
      if (this.dispatchOrderUser) {
        this.$emit('confirmDispatchOrder', this.dispatchOrderUser)
        this.dialogVisible = false
        return
      }
      this.$message.warning('请选择' + this.label)
    }
  }
}
</script>

<style scoped>

</style>